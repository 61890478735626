import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../../src/components/modules/Layout"
import ProductHeader from "../../../src/components/blocks/ProductHeader"
import ProductCard from "../../../src/components/molecules/ProductCard"
import Seo from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Seo"

const ProductGroupTemplate = ({ data }) => {
  const entry = data.entry
  const productGroups = data.productGroups.edges
  const line = data.line
  const area = data.area
  const variants = data.variants.edges
  let products = data.products.edges

  products.forEach((product) => {
    const allVariants = variants.filter(
      (v) =>
        v.node.parentId === product.node.ID &&
        v.node.language === product.node.language
    )
    const linkedVariant = allVariants[Math.floor((allVariants.length - 1) / 2)]
    if (linkedVariant) {
      product.node.variantFullSlug = linkedVariant.node.fullSlug
    }
  })

  let breadcrumbs = [
    { link: entry.rootPage.fullSlug, link_text: entry.rootPage.name },
    { link: area.fullSlug, link_text: area.name },
    { link: line.fullSlug, link_text: line.name },
  ]

  return (
    <Layout language={entry.language}>
      <Seo
        title={`${entry.name} | ${process.env.GATSBY_PRODUCT_AREAS_BASE}`}
        description={`${entry.name} | ${line.name} | ${area.name}`}
        language={entry.language}
      />
      <ProductHeader
        block={{ headline: entry.name }}
        breadcrumbs={breadcrumbs}
      />
      <section className="py-block">
        <div className=" block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            <div className="w-full mb-8 md:w-1/3 lg:w-1/4 px-grid">
              <div className="py-4 border border-gray-100">
                {productGroups.map((group) => {
                  return (
                    <div className="block" key={group.node.fullSlug}>
                      <Link
                        className={`inline-block py-2 text-sm px-grid hover:text-primary${
                          group.node.ID === entry.ID
                            ? " font-bold text-primary"
                            : ""
                        }`}
                        to={group.node.fullSlug}
                      >
                        {group.node.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="w-full md:w-2/3 lg:w-3/4 px-grid">
              <div className="flex flex-row flex-wrap -mx-grid">
                {products.map((product, index) => (
                  <div
                    className="flex w-full mb-8 px-grid md:w-1/2 lg:w-1/3"
                    key={index}
                  >
                    <ProductCard
                      item={{
                        id: product.node.ID,
                        headline: product.node.name,
                        text: product.node.hierarchy.group.name,
                        image: product.node.mainImage?.value || undefined,
                        treeSorting: product.node.treeSorting,
                        link:
                          product.node.variantFullSlug || product.node.fullSlug,
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ProductGroupQuery(
    $id: String
    $lineId: String
    $areaId: String
    $language: String
  ) {
    entry: contentServProductGroup(
      ID: { eq: $id }
      language: { eq: $language }
    ) {
      name
      language
      externalKey
      ID
      rootPage {
        name
        fullSlug
      }
    }
    productGroups: allContentServProductGroup(
      filter: {
        hierarchy: { line: { id: { eq: $lineId } } }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          name
          language
          fullSlug
          externalKey
          ID
        }
      }
    }
    line: contentServProductLine(
      ID: { eq: $lineId }
      language: { eq: $language }
    ) {
      name
      fullSlug
    }
    area: contentServProductArea(
      ID: { eq: $areaId }
      language: { eq: $language }
    ) {
      name
      fullSlug
    }
    products: allContentServProduct(
      filter: {
        hierarchy: { group: { id: { eq: $id } } }
        language: { eq: $language }
      }
      sort: { fields: treeSorting, order: ASC }
    ) {
      edges {
        node {
          name
          ID
          language
          treeSorting
          fullSlug
          parentId
          externalKey
          mainImage {
            value
          }
          hierarchy {
            area {
              id
            }
            group {
              name
              id
            }
          }
        }
      }
    }
    variants: allContentServVariant(
      filter: {
        hierarchy: { group: { id: { eq: $id } } }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          language
          fullSlug
          parentId
        }
      }
    }
  }
`

export default ProductGroupTemplate
